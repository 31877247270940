import React from 'react';
import { Admin, Resource, RouteWithoutLayout } from 'react-admin';
import i18nProvider from '../component/i18n/i18nProvider';
import { cbListGuesser } from '../component/corebosGuessers/cbListGuesser';
import { cbSOListGuesser } from '../component/SalesOrder/cbSOListGuesser';
import { cbInvListGuesser } from '../component/Invoice/cbInvListGuesser';
import { cbShowGuesser } from '../component/corebosGuessers/cbShowGuesser';
import { cbShowTabGuesser } from '../component/corebosGuessers/cbShowTabGuesser';
import { cbEditGuesser } from '../component/corebosGuessers/cbEditGuesser';
import { cbEditTabGuesser } from '../component/corebosGuessers/cbEditTabGuesser';
import { cbCreateGuesser } from '../component/corebosGuessers/cbCreateGuesser';
import { cbCreateTabGuesser } from '../component/corebosGuessers/cbCreateTabGuesser';
import { Route } from 'react-router-dom';
import MyLayout from '../component/layout/MyLayout';
import MyLoginPage from '../component/layout/MyLoginPage';
import ProfileShow from '../component/Contacts/ProfileShow';
import dataProvider from 'react-admin-corebos';
import authProvider from '../component/authProvider/authProvider';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ContactsIcon from '@material-ui/icons/Contacts';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import Dashboard from './dashboard/Dashboard';
import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import indigo from '@material-ui/core/colors/indigo';
import PayCommetInvoice from './payinvoice/PayCommetInvoice';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: blueGrey[500],
		},
		secondary: {
			main: blueGrey[500],
			/*light: '#5f5fc4',
			main: '#283593',
			dark: '#001064',
			contrastText: '#fff',*/
		},
	},
});


const App = () => (
	<Admin theme={theme} layout={MyLayout} loginPage={MyLoginPage} dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider}
			customRoutes={[
				<PayCommetInvoice
					exact
					path="/payinvoice"
					component={props => <PayCommetInvoice {...props} />}
				/>
			]}
		>
		{permissions => [
			<Resource
			name="SalesOrder"
			list={window.coreBOS.Describe.SalesOrder && window.coreBOS.Describe.SalesOrder.retrieveable ? cbSOListGuesser : null}
			icon={AccountBalanceIcon}
			options={{ label: 'Contratos' }}
			/>,
			<Resource
			name="Invoice"
			list={window.coreBOS.Describe.Invoice && window.coreBOS.Describe.Invoice.retrieveable ? cbInvListGuesser : null}
			options={{ label: 'Facturas' }}
			/>,
			<Resource name="Contacts"  list={ProfileShow} icon={ContactsIcon} options={{ label: 'Mi Cuenta' }}/>
		]
	}
	</Admin>
);

export default App;