import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ReceiptIcon from '@material-ui/icons/Receipt';


const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToPayButtonMobile = ({ invoice }) => {

    const classes = useStyles();
    if (invoice.invoicestatus !== 'Pago en revisión' && invoice.invoicestatus !== 'Paid') {
        return (
            <Button
                size="small"
                color="primary"
                component={Link}
                to={{
                    pathname: '/payinvoice',
                    state: {
                        invoice: invoice.id,
                        amountdue: invoice.amount_due,
                        total: invoice.hdnGrandTotal
                    }
                }}
                className={classes.link}
            >
            <ReceiptIcon className={classes.icon} />
            PAGAR
            </Button>
        );
    } else {
        return true;
    }
};


export default LinkToPayButtonMobile;
