import * as cbconn from 'corebos-ws-lib/WSClientm';
import config from '../../config';

if (window.coreBOS===undefined) {
	window.coreBOS = {};
}

window.addEventListener('coreBOSLoginEvent', async function (e) {
	window.coreBOS.Describe = {};
	window.coreBOS.ListViews = {};
	window.coreBOS.UserInfo = {};
	const data = await cbconn.doDescribe(config.DescribeModules);
	let coreboscalls = [];
	for (var [mod, desc] of Object.entries(data)) {
		window.coreBOS.Describe[mod] = desc;
		coreboscalls.push(getViewsByModule(mod));
		coreboscalls.push(getAssignedUserList(mod));
	}
	coreboscalls.push(getContactUserInfo());
	await Promise.all(coreboscalls);
}, false);

function getViewsByModule(mod) {
	return cbconn.doInvoke('getViewsByModule', { module: mod }, 'GET').then((views) => {
		window.coreBOS.ListViews[mod] = views;
	});
}

function getAssignedUserList(mod) {
	return cbconn.doInvoke('getAssignedUserList', { module: mod }, 'GET').then((usrs) => {
		window.coreBOS.Describe[mod].userlist = JSON.parse(usrs);
	});
}

function getContactUserInfo() {
	let resUser = localStorage.getItem('coreboslogindata') ? localStorage.getItem('coreboslogindata') : '';
	let userInfo = JSON.parse(resUser);
	return cbconn.doRetrieve(userInfo['user'].contactid).then((cntdata) => {
		if (cntdata['account_id'] === '') {
			cntdata['account_id'] = '1x1';
		}
		window.coreBOS.UserInfo['ContactInfo'] = cntdata;
	});
}

export default () => {
	window.coreBOS.Describe = {};
	window.coreBOS.ListViews = {};
	window.coreBOS.UserInfo = {};
	return cbconn.doDescribe(config.DescribeModules).then(async (data) => {
		let coreboscalls = [];
		for (var [mod, desc] of Object.entries(data)) {
			window.coreBOS.Describe[mod] = desc;
			coreboscalls.push(getViewsByModule(mod));
			coreboscalls.push(getAssignedUserList(mod));
		}
		coreboscalls.push(getContactUserInfo());
		await Promise.all(coreboscalls);
	});
};
