import spanishMessages from 'ra-language-spanish';

const ra = spanishMessages.ra;

ra.page.empty = 'Empty';
ra.page.invite = 'Invite';


const es = {
    ra: {
        action: {
            delete: 'Borrar',
            show: 'Mostrar',
            list: 'Listar',
            save: 'Guardar',
            create: 'Crear',
            edit: 'Editar',
            sort: 'Ordenar',
            cancel: 'Cancelar',
            undo: 'Deshacer',
            refresh: 'Refrescar',
            add: 'Agregar',
            remove: 'Remover',
            add_filter: 'Agregar filtro',
            remove_filter: 'Remover este filtro',
            back: 'Regresar',
            bulk_actions: '%{smart_count} seleccionado',
            clear_input_value: 'Limpiar valor',
            clone: 'Clonar',
            confirm: 'Confirmar',
            export: 'Exportar',
            search: 'Buscar',
            unselect: 'Desmarcar',
            expand: 'Expand',
            close: 'Cerrar',
            open_menu: 'Abrir menu',
            close_menu: 'Cerrar menu',
        },
        boolean: {
            true: 'Si',
            false: 'No',
            null: ' ',
        },
        page: {
            list: 'Lista de %{name}',
            edit: '%{name} #%{id}',
            error: 'Algo fue mal',
            list: '%{name}',
            show: '%{name} #%{id}',
            create: 'Crear %{name}',
            dashboard: 'Portada',
            not_found: 'No encontrado',
            loading: 'Cargando',
            empty: '%{name} vacío.',
            invite: '¿Quire añadir a alguien?',
        },
        input: {
            file: {
            upload_several: 'Arrastra algunos archivos para empezar la subida, o presiona aqui para seleccionarlos.',
            upload_single: 'Arrastra un archivo para empezar la subida, or presiona aqui para seleccionarlo.',
            },
            image: {
            upload_several: 'Arrastra algunas images para empezar la subida, or persiona aqui para seleccionar una.',
            upload_single: 'Arrastra una imagen aqui para empezar la subida, or presiona aqui para seleccionarla.',
            },
            references: {
            all_missing: 'No se encontro ninguna referencia.',
            many_missing: 'Al menos una de las referencias asociadas parece ya no estar disponible.',
            single_missing: 'La referencia asociada parece ya no estar disponible.',
            },
        },
        message: {
            yes: 'Si',
            no: 'No',
            are_you_sure: 'Estas seguro?',
            about: 'Acerca de',
            not_found: 'Parece que has escrito mal la URL, o que intentas a acceder a un link erroneo.',
            loading: 'La pagina esta cargando, espera un momento por favor',
            invalid_form: 'El formulario no es valido. Por favor busca por errores',
            delete_title: 'Borrar %{name} #%{id}',
            delete_content: 'Estas seguro que quieres borrar este elemento?',
            details: 'Details',
            bulk_delete_title: 'Borrar %{name} |||| Borrar %{smart_count} %{name} elementos',
            bulk_delete_content: 'Estas seguro de que quieres borrar este %{name}? |||| Estas seguro de que quieres borrar estos %{smart_count} elementos?',
            unsaved_changes: "Alguno de tus cambios no fueron guardados. ¿Estás seguro que quieres ignorarlos?",
        },
        navigation: {
            no_results: 'No se encontraron resultados',
            no_more_results: 'La pagina numero %{page} esta fuera de los limites. Prueba con la pagina anterior.',
            page_out_of_boundaries: 'La pagina numero %{page} se encuentra fuera de los limites',
            page_out_from_end: 'No se puede navegar despues de la ultima pagina',
            page_out_from_begin: 'No se puede navegar antes de la pagina 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
            page_rows_per_page: 'Filas por página',
            next: 'Siguiente',
            prev: 'Previo',
            skip_nav: 'Volver al contenido',
        },
        sort: {
            sort_by: 'Ordenar por %{field} %{order}',
            ASC: 'ascendente',
            DESC: 'descendente',
        },
        auth: {
            auth_check_error: 'Por favor, incia sesión para continuar',
            username: 'Usuario',
            password: 'Contraseña',
            user_menu: 'Mi usuario',
            sign_in: 'Iniciar Sesión',
            sign_in_error: 'Error de autenticacion, por favor reintenta',
            logout: 'Cerrar sesion',
        },
        notification: {
            updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados',
            created: 'Elemento creado',
            deleted: 'Elemento borrado |||| %{smart_count} elementos borrados',
            bad_item: 'Elemento incorrecto',
            item_doesnt_exist: 'El elemento no existe',
            http_error: 'Error de comunicacion con el servidor',
            data_provider_error: 'dataProvider error. Check the console for details.',
            i18n_error: 'Cannot load the translations for the specified language',
            canceled: 'Accion cancelada',
            logged_out: 'Tu sesión ha finalizado, por favor vuelve a iniciar sesión.',
        },
        validation: {
            required: 'Requerido',
            minLength: 'Debe tener al menos %{min} caracteres',
            maxLength: 'Deber tener %{max} caracteres o menos',
            minValue: 'De ser al menos %{min}',
            maxValue: 'Debe ser %{max} o menos',
            number: 'Debe ser un number',
            email: 'Debe ser un correo electronico valido',
            oneOf: 'Debe ser uno de los siguientes valores: %{options}',
            regex: 'Debe seguir un formato especifico (regexp): %{pattern}',
        },
        },
    reimbursement: {
        summary_canceled: "Sommario Annulli",
        canceled_deposit_requests: "Richieste Annulli al Deposita",
        working_practices: "Pratiche in lavorazine",
        practices_with_anomalies: "Pratiche con anomalie",
        closed_practices: "Pratiche chiuse/concluse",
    },
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Identificador de sesión inválido, intentalo de nuevo.',
};

export default es;
