import * as React from 'react';
import { FC } from 'react';
import LinkToPayButtonMobile from './LinkToPayButtonMobile';
import {
	Card,
	CardMedia,
	CardContent,
	CardHeader,
	CardActions,
	Typography,
	Avatar
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import {
	DateField,
	EditButton,
	useTranslate,
	NumberField,
	Identifier,
	TextField,
	Labeled,
	SelectField,
	FileField
} from 'react-admin';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
	root: { margin: '1em' },
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '0.5rem 0',
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	cardContent: {
		...theme.typography.body1,
		display: 'flex',
		flexDirection: 'column',
	},
	actionSpacer: {
		display: 'flex',
		justifyContent: 'space-around',
	},
	icon: { paddingRight: '0.5em' },
	link: {
		display: 'inline-flex',
		alignItems: 'center',
	},
}));

interface Props {
	ids?: Identifier[];
	data?: { [key: string]: Customer };
	basePath?: string;
}

const MobileGrid: FC<Props> = ({ ids, data, basePath }) => {
	const translate = useTranslate();
	const classes = useStyles();
	var total = 0.00;

	const formattedValue = new Intl.NumberFormat("es-ES", {
	  style: "currency",
	  currency: "EUR"
	});

	if (!ids || !data) {
		return null;
	}

	return (
		<div className={classes.root}>
			{ids.map(id => (
				<Card key={id} className={classes.card}>
					<CardHeader
						title= {data[id].num_cliente + ' / ' + data[id].num_contrato}
						subheader={<TextField record={data[id]} source="ship_street" />}
					/>
					<CardContent className={classes.cardContent}>
						<Labeled label="Nº FACTURA">
							<TextField record={data[id]} source="subject" />
						</Labeled>
						<Labeled label="FECHA FACTURA">
							<DateField record={data[id]} source="invoicedate" />
						</Labeled>
						<Labeled label="ESTADO">
							<SelectField record={data[id]} source="invoicestatus" 
								choices={[
								{ id: 'Paid', name: 'Pagada' },
								{ id: 'Created', name: 'Pendiente' },
								{ id: 'Cancel', name: 'Pendiente' },
								{ id: 'AutoCreated', name: 'Pendiente' },
								{ id: 'Approved', name: 'Pendiente' },
								{ id: 'Sent', name: 'Pendiente' },
								{ id: 'Credit Invoice', name: 'Pendiente' },
								{ id: 'Pago en revisión', name: 'Pago en revisión' },
								]} 
								optionText="name" optionValue="id" />
						</Labeled>
						<Labeled label="PENDIENTE DE PAGAR">
						<Typography
							variant="body2"
							component="span"
							>
								{formattedValue.format(data[id].amount_due)}
							</Typography>
						</Labeled>
						<Labeled label="IMPORTE">
							<Typography
							variant="body2"
							component="span"
							>
								{formattedValue.format(data[id].hdnGrandTotal)}
							</Typography>
						</Labeled>
					</CardContent>

					<CardActions classes={{ spacing: classes.actionSpacer }}>
						<Button
							variant="contained"
							size="small"
							color="primary"
							className={classes.link}
							startIcon={<ReceiptIcon />}
							href={data[id].factura_adjuntafullpath}
							target="_blank"
							rel="noreferrer"
						>
						DESCARGAR
						</Button>
					</CardActions>
					<CardActions classes={{ spacing: classes.actionSpacer }}>
							<LinkToPayButtonMobile invoice={data[id]} />
					</CardActions>
				</Card>
			))}
		</div>
	);
};

MobileGrid.defaultProps = {
	data: {},
	ids: [],
};

export default MobileGrid;