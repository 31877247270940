import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import cbUtils from '../corebosUtils/corebosUtils';


const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToRelatedInvoice = props => {

    const classes = useStyles();
    return (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/Invoice',
                search: stringify({
                    filter: JSON.stringify({"salesorder_id":props.record[props.source]}),
                }),
            }}
            className={classes.link}
        >
        VER FACTURAS
        </Button>
    );
};


export default LinkToRelatedInvoice;
