import React from 'react';
import { TextField, EmailField, Labeled } from 'react-admin';
import {
	Card,
	CardContent,
	CardHeader,
	Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
	root: { margin: '1em' },
	card: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0.5rem 0',
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	cardContent: {
		...theme.typography.body1,
		display: 'flex',
		flexDirection: 'column',
	},
	actionSpacer: {
		display: 'flex',
		justifyContent: 'space-around',
	},
}));

const ProfileShow = ({ ...props }) => {
	let logdata = window.coreBOS.UserInfo['ContactInfo'];
	const classes = useStyles();
	
	return (
		<div className={classes.root}>

				<Card className={classes.card}>
					<CardHeader
						title= "DATOS PERSONALES"
					/>
					 <Divider />
					<CardContent className={classes.cardContent}>
						<Labeled label="NOMBRE DEL TITULAR">
							<TextField record={logdata} source="firstname" />
						</Labeled>
						<Labeled label="TELÉFONO DE CONTACTO">
							<TextField record={logdata} source="mobile" />
						</Labeled>
						<Labeled label="EMAIL DE CONTACTO">
							<TextField record={logdata} source="email" />
						</Labeled>
					</CardContent>
				</Card>
				<Card className={classes.card}>
					<CardHeader
						title= "RECTIFICAR DATOS PERSONALES"
						subheader= "Si necesita rectificar sus datos personales, por favor pongase en contacto con nosotros utilizando alguno de los medios que indicamos abajo."
					/>
					 <Divider />
					<CardContent className={classes.cardContent}>
						<Labeled label="OFICINA">
							<TextField emptyText="
								Oficinas en Oliva (CP: 46780 ),
								calle Roger de Lauria nº.48 bajo.
								(Roger de Llúria 48 baix)" />
						</Labeled>
						<Labeled label="TELÉFONO">
							<TextField emptyText="96 285 20 62" />
						</Labeled>
						<Labeled label="EMAIL">
							<EmailField emptyText="apposa@apposa.com" />
						</Labeled>
					</CardContent>
				</Card>
		</div>
	);
};
export default ProfileShow;