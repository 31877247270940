import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
	main: { background: '#607d8b' },
	avatar: {
		background: 'url(logo_apposa_blanco_512.png)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		height: 180,
	},
	icon: { display: 'none' },
});

const CustomLoginForm = withStyles({
	button: { background: '#F15922' },
})(LoginForm);

const MyLoginPage = props => (
	<Login
		loginform={<CustomLoginForm />}
		{...props}
	/>
);

export default withStyles(styles)(MyLoginPage);