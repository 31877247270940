import React from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ContactsIcon from '@material-ui/icons/Contacts';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';

export default {
  AppName: "coreBOS Portal",
  Server: {
    url: 'https://corebos.apposa.com/',
    //url: 'http://localhost/corebos/apposa/',
  },
  DescribeModules: [
    'Accounts', 'Contacts', 'SalesOrder', 'Invoice'
  ],
  ModuleIcons: {
    'SalesOrder': <AccountBalanceIcon />,
    'Invoice': <ContactsIcon />,
    'Contacts': <ContactsIcon />,
  },
}
