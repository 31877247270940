import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import cbUtils from '../corebosUtils/corebosUtils';


const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToPayButton = props => {
    const classes = useStyles();
    if (props.record['invoicestatus'] !== 'Pago en revisión' && props.record['invoicestatus'] !== 'Paid') {
        return (
            <Button
                size="small"
                color="primary"
                component={Link}
                to={{
                    pathname: '/payinvoice',
                    state: {
                        invoice: props.record['id'],
                        amountdue: props.record['amount_due'],
                        total: props.record['hdnGrandTotal']
                    }
                }}
                className={classes.link}
            >
            PAGAR
            </Button>
        );
    } else {
        return true;
    }
};


export default LinkToPayButton;
