import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import sha512 from 'js-sha512';
import md5 from 'md5';

const useStyles = makeStyles((theme) => ({
  grow: {
	flexGrow: 1,
  },
  title: {
	display: 'none',
	overflow: 'unset',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  root: {
    width: '100%',
    maxWidth: '136ch',
    backgroundColor: theme.palette.background.paper,
	margin: 'auto',
	marginTop: '0.4rem',
  },
  margtop: {
	marginTop: '0.2rem',
	borderBottom: "1px solid",
  },
  inline: {
    display: 'inline',
  },
}));
const PayCommetInvoice = props => {
	const classes = useStyles();
	const translate = useTranslate();
  //console.log(props.location.state); //De aquí recogemos los parametros enviados desde el botón de pagar.
  // Entorn REAL
  let merchantcode = 'ut9dddp9';
  let password = 'ct5axprxbzyrvy9cf4mw';
  let terminal = '26074';

  // Entorn PROVES
  //let merchantcode = 'sqaqk9yv';
  //let password = 'gy39zwung3mysdxs8n2e';
  //let terminal = '26075';

  // COMU
  let operation = '1';
  let language = 'ES';
  let date = Date.now();
  let order = props.location.state.invoice+'_'+date;
  // No recupera esta propietat correctament (NaN €)
  let amount = props.location.state.amountdue*100;
  
  let currency = 'EUR';
  let description = 'Factura APPOSA';
  let signature = sha512(merchantcode+terminal+operation+order+amount+currency+md5(password));
  let url = 'https://api.paycomet.com/gateway/ifr-bankstore?3DSECURE=0&OPERATION='+operation+'&MERCHANT_MERCHANTCODE='+merchantcode+'&MERCHANT_TERMINAL='+terminal+'&LANGUAGE='+language+'&MERCHANT_CURRENCY='+currency+'&MERCHANT_ORDER='+order+'&MERCHANT_AMOUNT='+amount+'&MERCHANT_MERCHANTSIGNATURE='+signature;

  return (
    <div className={classes.grow}>
      {amount > 0 &&
      <iframe title="Pagar Factura" src={url} width="100%" height="525" frameborder="0" marginheight="0" marginwidth="0" scrolling="no" >
      </iframe>
      }
      {amount <= 0 &&
        <div>La factura está completamente pagada.</div>
      }
    </div>
  );
};
export default PayCommetInvoice;
