import React from 'react';
import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import cbUtils from '../corebosUtils/corebosUtils';
import ReceiptIcon from '@material-ui/icons/Receipt';


const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToRelatedInvoiceMobile = ({ contract }) => {

    const classes = useStyles();
    return (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/Invoice',
                search: stringify({
                    filter: JSON.stringify({"salesorder_id":contract.subject}),
                }),
            }}
            className={classes.link}
        >
        <ReceiptIcon className={classes.icon} />
        VER FACTURAS
        </Button>
    );
};


export default LinkToRelatedInvoiceMobile;
