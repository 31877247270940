import * as React from 'react';
import { FC } from 'react';
import LinkToRelatedInvoiceMobile from './LinkToRelatedInvoiceMobile';
import inflection from 'inflection';
import {
	Card,
	CardMedia,
	CardContent,
	CardHeader,
	CardActions,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	DateField,
	EditButton,
	useTranslate,
	NumberField,
	Identifier,
	TextField,
	Labeled
} from 'react-admin';


const useStyles = makeStyles(theme => ({
	root: { margin: '1em' },
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '0.5rem 0',
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	cardContent: {
		...theme.typography.body1,
		display: 'flex',
		flexDirection: 'column',
	},
	actionSpacer: {
		display: 'flex',
		justifyContent: 'space-around',
	},
}));

interface Props {
	ids?: Identifier[];
	data?: { [key: string]: Customer };
	basePath?: string;
}

const MobileGrid: FC<Props> = ({ ids, data, basePath }) => {
	const translate = useTranslate();
	const classes = useStyles();

	if (!ids || !data) {
		return null;
	}

	return (
		<div className={classes.root}>
			{ids.map(id => (
				<Card key={id} className={classes.card}>
					<CardHeader
						title= {<TextField record={data[id]} source="bill_street" />}
					/>
					<CardContent className={classes.cardContent}>
						<Labeled label="Nº CONTRATO">
							<TextField record={data[id]} source="subject" />
						</Labeled>
						<Labeled label="Nº CONTADOR">
							<TextField record={data[id]} source="num_contador" />
						</Labeled>
					</CardContent>

					<CardActions
							classes={{ spacing: classes.actionSpacer }}
						>
							<LinkToRelatedInvoiceMobile contract={data[id]} />
					</CardActions>
				</Card>
			))}
		</div>
	);
};

MobileGrid.defaultProps = {
	data: {},
	ids: [],
};

export default MobileGrid;